import { TableApiInterface, TableTitle } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';

/**
 *
 *
 * @param {Object} table employees data
 * @param {Object} state component's state
 * @returns {Object} tableInfo
 */
export function prepareList(
  table: TableApiInterface
): {
  titles?: TableTitle[];
  rows?: { [p: string]: string | number | boolean }[];
  pagination?: { currentPage: number; totalItems: number; totalPages: number };
  totalItems?: number;
  sortFields?: string[];
  filters?: { name: string; value: string | null }[];
  totalData?: { [p: string]: string | number };
  additionalInfo?: { [p: string]: string };
} {
  const uiTable = new UITable(
    table as {
      titles?: TableTitle[];
      rows?: { [p: string]: string | number | boolean }[];
      pagination?: { currentPage: number; totalItems: number; totalPages: number };
      totalItems?: number;
      sortFields?: string[];
      filters?: { name: string; value: string | null }[];
      totalData?: { [p: string]: string | number };
      additionalInfo?: { [p: string]: string };
    }
  );

  return uiTable
    .removeColumn('start')
    .removeColumn('end')
    .removeColumn('isTest')
    .removeColumn('isTestMarket')
    .removeColumn('isTestClient')

    .addColumnByIndex(4, { id: 'time', name: 'Время', visible: true })

    .setProperty('isProcessed', 'name', 'Просмотрено')
    .setProperty('serviceTypeName', 'name', 'Тип услуги')
    .setProperty('market', 'name', 'Объект')

    .getTable() as {
    titles?: TableTitle[];
    rows?: { [p: string]: string | number | boolean }[];
    pagination?: { currentPage: number; totalItems: number; totalPages: number };
    totalItems?: number;
    sortFields?: string[];
    filters?: { name: string; value: string | null }[];
    totalData?: { [p: string]: string | number };
    additionalInfo?: { [p: string]: string };
  };
}
