
import { ref } from 'vue';
import { Component, Vue } from '@/lib/decorator';
import TitlePage from '@/components/Title.vue';
import AppFilterClearBtn from '@/layouts/partials/AppFilterClearBtn.vue';
import FilterBlock from '@/components/Filter.vue';
import ModalBlock from '@/components/Modal.vue';
import FormInput from '@/components/form/Input/index.vue';
import FormSelect from '@/components/form/Select/index.vue';
import FormAutocomplete from '@/components/form/Autocomplete/index.vue';

import FirstShiftModule from '@/store/tasks/firstShift';
import ShopShiftsModule from '@/store/shops/shift';
import EmployeesCalendarModule from '@/store/employees/calendar';
import { EventBus } from '@/lib/event-bus';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TasksEntityModule from '@/store/tasks/entity';
import TitleReturn from '@/components/TitleReturn.vue';
import AppTable from '@/components/ui/table/Table.vue';
import AppSimpleCheckbox from '@/components/ui/form/SimpleCheckbox.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import TextPhone from '@/components/table-items/TextPhone.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import FormSimpleSwitch from '@/components/form/Switch/SimpleSwitch.vue';
import IconFont from '@/components/icons/IconFont.vue';
import { getEmployeePhone } from '@/api/employees';
import { makePhoneCall } from '@/lib/util/phone';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { FilterEntityModel, FilterSettings } from '@/lib/layouts/page/filter.interface';
import { modalParamsInterface } from '@/store/employees/calendarModalParams';
import { ShiftModalParamsInterface } from '@/store/shops/shiftModalParams';
import { Checkbox } from '@/interfaces/filter.interface';
import ShiftModal from '@/views/shops/shift/form/shiftModal.vue';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    AppCol,
    AppRow,
    TextPhone,
    TextDatetime,
    AppSimpleCheckbox,
    AppTable,
    TitleReturn,
    TabsNav,
    TitlePage,
    AppFilterClearBtn,
    FilterBlock,
    ModalBlock,
    FormInput,
    FormSelect,
    FormAutocomplete,
    FormSimpleSwitch,
    ShiftModal,
    IconFont,
  },
})
export default class FirstShiftMain extends Vue {
  filter = ref(null);

  isShowShiftModal = false;
  shopId?: string;
  serviceTypeId?: string;
  shiftIdForModal?: string;

  get fullName(): FilterEntityModel {
    return FirstShiftModule.filter.filterSettings.filterModel.partnerUuid;
  }

  get tabsNav(): TabsNavInterface {
    return TasksEntityModule.tabsNav;
  }

  get currentTab(): string {
    return TasksEntityModule.tabsNav.firstShift?.id;
  }

  get settings(): PageInterface {
    return FirstShiftModule.pageSettings;
  }

  get filterSettings(): FilterSettings {
    return FirstShiftModule.filter.filterSettings;
  }

  get processed(): FilterEntityModel {
    return FirstShiftModule.filter.filterSettings.filterModel.processed;
  }

  get processedItem(): Checkbox {
    return (FirstShiftModule.filter.filterSettings.filterModel.processed.list as Checkbox[])[0];
  }

  get selectRange(): FilterEntityModel {
    return FirstShiftModule.filter.filterSettings.filterModel.range;
  }

  get modalParams(): modalParamsInterface {
    return EmployeesCalendarModule.modalParams;
  }

  get modalParamsMarket(): ShiftModalParamsInterface {
    return ShopShiftsModule.modalParams;
  }

  get editEntityType(): string {
    return EmployeesCalendarModule.modalParams.edit.entityType;
  }

  async handleSelect(selected: {}, params: { action: string; key: string }): Promise<void> {
    await this.$store.dispatch(params.action, {
      key: params.key,
      value: selected,
    });

    await FirstShiftModule.SET_PAGE(1);
    await FirstShiftModule.filter.updateFilter();

    FirstShiftModule.getList();
  }

  async handleCheckbox(value: boolean, key: string, actionName: string): Promise<void> {
    await this.$store.dispatch(actionName, {
      key,
      checkbox: {
        bool: value,
        id: 'processed',
        name: 'Просмотренные',
      },
    });

    FirstShiftModule.getList();
  }

  async makeCall(employeeId: string): Promise<void> {
    try {
      const phone = await getEmployeePhone(employeeId);
      makePhoneCall(phone);
    } catch (error) {
      console.error(error);
    }
  }

  updateProcessed(id: string, bool: boolean): void {
    FirstShiftModule.updateProcessed({ id, bool });
  }

  openShift(item: { marketId: number; serviceTypeId: number; clientMarketShiftId: number }): void {
    this.shopId = item.marketId?.toString();
    this.serviceTypeId = item.serviceTypeId?.toString();
    this.shiftIdForModal = item.clientMarketShiftId.toString();
    this.isShowShiftModal = true;
  }

  selectAmount(value: string): void {
    FirstShiftModule.updatePageAmountItems(value);
  }

  savedEditShift(): void {
    FirstShiftModule.getList();
  }

  iconWhoseType(value: string): { icon: string; tooltip: string } {
    switch (value) {
      case 'fromPartner': {
        return { icon: 'partners', tooltip: 'От партнёра' };
      }
      case 'fromHome': {
        return { icon: 'home', tooltip: 'Свой' };
      }
      case 'fromAgent': {
        return { icon: 'scout', tooltip: 'От скаута' };
      }
    }

    return { icon: '', tooltip: '' };
  }

  mounted(): void {
    TasksEntityModule.initTabsItems();
    EventBus.$on('savedShift', this.savedEditShift);
    FirstShiftModule.init();
  }

  unmounted(): void {
    EventBus.$off('savedShift', this.savedEditShift);
  }
}
