
import { Component, Vue } from '@/lib/decorator';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

library.add(faFilter);

@Component({})
export default class AppFilterClearBtn extends Vue {
  clearSort() {
    this.$emit('click');
  }
}
