import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import PageBaseModule from '@/store/page';
import { Filter } from '@/lib/layouts/page/filter.interface';
import PageEntity from '@/lib/layouts/page/pageEntity';
import filterModel from '@/store/tasks/firstShift/filter';
import FirstShiftFilter from '@/store/tasks/firstShift/filterEntity';
import { getFirstShiftList, updateIsProcessed } from '@/api/firstShift';
import { prepareList } from '@/lib/FirstShift';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { strings } from '@/lib/stringConst';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { getAllPartnersList } from '@/api/partners';

export const MODULE_NAME = 'firstShift';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class FirstShiftModule extends PageBaseModule {
  shift: {} = {};
  defaultFilter: string;
  filter: Filter;

  constructor(module: FirstShiftModule) {
    super(module);

    const page = new PageEntity();

    page.values.actionPagination = 'firstShift/updatePage';
    this.pageSettings = page.values;
    this.pageSettings.actionsHandler = {
      additional: {
        fullName: 'firstShift/getById',
        market: 'firstShift/getById',
        client: 'firstShift/getById',
        vacancy: 'firstShift/getById',
        time: 'firstShift/getShiftById',
      },
    };

    const filter = new filterModel();
    this.defaultFilter = 'filters[0][id]=range&filters[0][value]=today';
    this.filter = getModule(FirstShiftFilter);
    this.filter.setFilterName('firstShiftFilter');
    this.filter.setTemplateClassName('template-sm');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);
  }

  @Mutation
  SET_SHIFT(shift: {}) {
    this.shift = shift;
  }

  @Mutation
  CHANGE_CURRENT_PAGE(pageNumber: number) {
    this.pageSettings.pageCurrent = pageNumber;
  }

  @Action({ rawError: true })
  async init() {
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.initList();
  }

  @Action({ rawError: true })
  async initList() {
    await this.filter.init();
    await this.context.dispatch('getList');
  }

  @Action({ rawError: true })
  async getList() {
    try {
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const filter = this.filter.filterSettings.filter ? this.filter.filterSettings.filter : this.defaultFilter;
      const result = await getFirstShiftList(this.pageSettings.pageCurrent, filter, itemsQuery);
      const partnersList = await getAllPartnersList();
      await this.context.dispatch('setItemsFilter', partnersList);
      await this.context.dispatch('setList', result.table);
    } catch (error) {
      this.context.commit('setGlobalError', false);
    }
  }

  @Action({ rawError: true })
  setItemsFilter(partnersList: any) {
    const items = [] as { id: string; value: string }[];
    partnersList.forEach((item: any) => {
      items.push({ id: item.uuid.toString(), value: item.legalName });
    });
    this.filter.updateSelectList({ key: 'partnerUuid', list: items });
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    this.context.commit('SET_TABLE', prepareList(table));
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.getList();
  }

  @Action({ rawError: true })
  updateSelectRange(selected: {}) {
    this.context.commit('SET_FILTER_RANGE', selected);
    this.getList();
  }

  @Action({ rawError: true })
  setShift(shift: {}) {
    this.context.commit('SET_SHIFT', shift);
  }

  @Action({ rawError: true })
  async updateProcessedFilter(params: { key: string; checkbox: { bool: boolean; id: string; name: string } }) {
    await this.filter.updateCheckbox(params);
    this.filter.updateFilter();
  }

  @Action({ rawError: true })
  async updateRange(params: { key: string; value: { id: number; value: string } }) {
    await this.filter.updateSelect(params);
    this.filter.updateFilter();
  }

  @Action({ rawError: true })
  async updatePartner(params: { key: string; value: { id: number; value: string } }) {
    await this.filter.updateSelect(params);
    this.filter.updateFilter();
  }

  @Action({ rawError: true })
  async updateEmployee(params: { key: string; value: { id: number; value: string } }) {
    await this.filter.updateSelect(params);
    this.filter.updateFilter();
  }

  @Action({ rawError: true })
  async updateProcessed(params: { id: string; bool: boolean }) {
    try {
      const data = {
        employeeShift: params.id,
        processed: params.bool ? 1 : 0,
      };

      const result = await updateIsProcessed(data);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }
}

export default getModule(FirstShiftModule);
