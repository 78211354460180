export const modalParams = {
  add: {
    isShow: false,
    title: 'Добавить рабочее время',
    model: {
      intervalStart: {
        key: 'intervalStart',
        type: 'timePicker',
        title: 'Интервал смены',
        value: '7:00',
        hour: '17',
        minute: '00',
        action: 'employeeShifts/updateIntervalStart',
        required: true,
        start: 0,
        end: 24,
        step: 30,
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      intervalEnd: {
        key: 'intervalEnd',
        type: 'timePicker',
        title: '',
        value: '12:00',
        hour: '22',
        minute: '00',
        action: 'employeeShifts/updateIntervalEnd',
        required: true,
        start: 0,
        end: 24,
        step: 30,
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      dates: [],
      includeWeekdays: {
        name: 'includeWeekdays',
        type: 'checkbox',
        list: {
          0: {
            id: 1,
            name: 'Добавить будни',
            checked: true,
          },
        },
        handler: 'employeeShifts/updateIncludeWeekdays',
      },
      includeWeekends: {
        name: 'includeWeekends',
        type: 'checkbox',
        list: {
          0: {
            id: 1,
            name: 'Добавить выходные (Сб, Вс)',
            checked: true,
          },
        },
        handler: 'employeeShifts/updateIncludeWeekends',
      },
    },
  },
  deleteWarning: {
    shopId: null,
    vacancyId: null,
    text: 'Вы уверены?',
    handlers: {
      yes: 'employeeShifts/deleteShift',
      no: '',
    },
    employeeId: '',
  },
  delete: {
    isShow: false,
    title: 'Удалить время/заказ',
    model: {
      dateStart: {
        key: 'dateStart',
        type: 'text',
        title: 'С даты',
        value: '28.02.2020',
        currentValue: {
          month: '',
          day: '',
        },
        action: 'employeeShifts/updateDeleteDateStart',
        required: true,
        size: 40,
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      dateEnd: {
        key: 'dateEnd',
        type: 'text',
        title: 'До даты',
        value: '29.02.2020',
        currentValue: {
          month: '',
          day: '',
        },
        action: 'employeeShifts/updateDeleteDateEnd',
        required: true,
        size: 40,
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      isTimeInterval: {
        name: 'isTimeInterval',
        type: 'checkbox',
        list: {
          0: {
            id: 1,
            name: 'Попадают в интервал',
            checked: false,
          },
        },
        handler: 'employeeShifts/updateIsTimeInterval',
      },
      intervalStart: {
        key: 'intervalStart',
        type: 'timePicker',
        title: 'Попадают в интервал',
        value: '7:00',
        action: 'employeeShifts/updateDeleteIntervalStart',
        required: true,
        hour: '17',
        minute: '00',
        start: 0,
        end: 24,
        step: 30,
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      intervalEnd: {
        key: 'intervalEnd',
        type: 'timePicker',
        title: '',
        value: '12:00',
        hour: '22',
        minute: '00',
        action: 'employeeShifts/updateDeleteIntervalEnd',
        required: true,
        start: 0,
        end: 24,
        step: 30,
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      isExcludeWeekday: {
        name: 'isExcludeWeekday',
        type: 'checkbox',
        list: {
          0: {
            id: 1,
            name: 'Удалить будни',
            checked: true,
          },
        },
        handler: 'employeeShifts/updateDeleteIsExcludeWeekday',
      },
      isExcludeWeekends: {
        name: 'isExcludeWeekends',
        type: 'checkbox',
        list: {
          0: {
            id: 1,
            name: 'Удалить выходные (Сб, Вс)',
            checked: true,
          },
        },
        handler: 'employeeShifts/updateDeleteIsExcludeWeekends',
      },
      isExcludeShifts: {
        name: 'isExcludeShifts',
        type: 'checkbox',
        list: {
          0: {
            id: 1,
            name: 'Удалить смены',
            checked: false,
          },
        },
        handler: 'employeeShifts/updateIsExcludeShifts',
      },
    },
  },
  edit: {
    isShow: false,
    title: 'Добавить рабочее время',
    shiftId: '',
    entityType: '',
    howMuchTime: '',
    model: {
      intervalStart: {
        key: 'intervalStart',
        type: 'timePicker',
        title: 'Интервал смены',
        value: '17:00',
        hour: '17',
        minute: '00',
        action: 'employeeShifts/updateEditIntervalStart',
        required: true,
        start: 0,
        end: 24,
        step: 30,
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      intervalEnd: {
        key: 'intervalEnd',
        type: 'timePicker',
        title: '',
        value: '22:00',
        hour: '22',
        minute: '00',
        action: 'employeeShifts/updateEditIntervalEnd',
        required: true,
        start: 0,
        end: 24,
        step: 30,
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
    },
  },
  log: {
    isShow: false,
    title: 'Логи',
  },
};

export interface intervalInterface {
  key: string;
  type: string;
  title: string;
  value: string;
  hour: string;
  minute: string;
  action: string;
  required: boolean;
  start: number;
  end: number;
  step: number;
  error: {
    class: string;
    message: string;
  };
  validation: {
    empty: string;
  };
}

export interface dateInterface {
  key: string;
  type: string;
  title: string;
  value: string;
  currentValue: {
    month: string;
    day: string;
  };
  action: string;
  required: boolean;
  size: number;
  mask?: {
    regex: string;
    placeholder: string;
    minChars: number;
  };
  error: {
    class: string;
    message: string;
  };
  validation: {
    empty: string;
  };
}

export interface includeExcludeInterface {
  name: string;
  type: string;
  list: {
    0: {
      id: number;
      name: string;
      checked: boolean;
    };
  };
  handler: string;
}

export interface modalParamsAddModelInterface {
  intervalStart: intervalInterface;
  intervalEnd: intervalInterface;
  dates: string[];
  includeWeekdays: includeExcludeInterface;
  includeWeekends: includeExcludeInterface;
}

export interface modalParamsAddInterface {
  isShow: boolean;
  title: string;
  model: modalParamsAddModelInterface;
}

export interface modalParamsDeleteWarningInterface {
  shopId: number | null;
  vacancyId: number | null;
  text: string;
  handlers: {
    yes: string;
    no: string;
  };
  employeeId: string;
}

export interface modalParamsDeleteInterface {
  isShow: boolean;
  title: string;
  model: {
    dateStart: dateInterface;
    dateEnd: dateInterface;
    isTimeInterval: includeExcludeInterface;
    intervalStart: intervalInterface;
    intervalEnd: intervalInterface;
    isExcludeWeekday: includeExcludeInterface;
    isExcludeWeekends: includeExcludeInterface;
    isExcludeShifts: includeExcludeInterface;
  };
}

export interface modalParamsEditInterface {
  isShow: boolean;
  title: string;
  shiftId: string;
  entityType: string;
  howMuchTime: string;
  model: {
    intervalStart: intervalInterface;
    intervalEnd: intervalInterface;
  };
}

export interface modalParamsLogInterface {
  isShow: boolean;
  title: string;
}

export interface modalParamsInterface {
  [key: string]:
    | modalParamsAddInterface
    | modalParamsDeleteWarningInterface
    | modalParamsDeleteInterface
    | modalParamsEditInterface
    | modalParamsLogInterface;
  add: modalParamsAddInterface;
  deleteWarning: modalParamsDeleteWarningInterface;
  delete: modalParamsDeleteInterface;
  edit: modalParamsEditInterface;
  log: modalParamsLogInterface;
}
