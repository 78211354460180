import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class FirstShiftFilter {
  filterHandlers = {
    update: 'firstShiftFilter/updateFilter',
    reset: 'firstShiftFilter/resetFilter',
    resetState: 'firstShiftFilter/resetFilterState',
    removeSelected: 'firstShiftFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();

    filter.createSelectEntity('range');
    filter.setName('range', 'select_range');
    filter.setAction('range', 'firstShift/updateRange');
    filter.setList('range', [
      { id: 'today', value: 'Сегодня' },
      { id: 'tomorrow', value: 'Завтра' },
    ]);
    filter.setClearAction('range', '');

    filter.createCheckboxEntity('processed');
    filter.setTitle('processed', 'Просмотренные');
    filter.setName('processed', 'checkbox_isProcessed');
    filter.setAction('processed', 'firstShift/updateProcessedFilter');
    filter.setListElement('processed', {
      id: 'processed',
      name: 'Просмотренные',
      checked: false,
    });

    filter.createSelectEntity('partnerUuid');
    filter.setTitle('partnerUuid', 'Партнёр исполнителя');
    filter.setName('partnerUuid', 'select_partnerUuid');
    filter.setPreviewName('partnerUuid', 'Партнёр исполнителя');
    filter.setAction('partnerUuid', 'firstShift/updatePartner');
    filter.setClearAction('partnerUuid', '');

    this.filterModel = filter.filterModel;
  }
}
