/**
 * Employees calendar store.
 *
 */

import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import CalendarBaseModule from '@/store/calendar';
import { modalParams, modalParamsInterface } from './calendarModalParams';
import CalendarAbstract from '@/lib/Calendar';
import { shiftsEmployeeProcessed } from '@/lib/Shift';

import { getEmployeesShifts, addNewShift, updateReadyTimeInfo, deleteShift, deleteReadyTimeById } from '@/api/employees';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import ModalsModule from '@/store/modals';
import SiteModule from '@/store/site';
import ShopShiftsModule from '@/store/shops/shift';
import EmployeeEntityModule from '@/store/employees/entity';

import moment from 'moment/moment.js';
import { CalendarItem, ShiftInterface } from '@/interfaces/shift.interface';
import { strings } from '@/lib/stringConst';
import { CatchFormResponse } from '@/interfaces/shared';

export const MODULE_NAME = 'employeeShifts';

export interface StyleInterface {
  hourGrid: Record<string, { gridRow: string; gridRowEnd: string }>;
  max: number;
  min: number;
  weekStyle: string;
}

interface HoursInterface {
  gridRow: string;
  gridRowEnd: string;
  hour: number;
  text: string;
  textMob: string;
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class EmployeesCalendarModule extends CalendarBaseModule {
  entityTypes = ['ready', 'shift'];
  modalParams: modalParamsInterface = { ...modalParams };

  constructor(module: EmployeesCalendarModule) {
    super(module);

    this.calendarEntity = new CalendarAbstract();
    this.text.deleteBtn = 'Редактировать';
  }

  @Mutation
  SET_COUNT_START_WEEK(startWeek: number) {
    this.countWeekStart = startWeek;
  }

  @Mutation
  SET_COUNT_END_WEEK(endWeek: number) {
    this.countWeekEnd = endWeek;
  }

  @Mutation
  SET_START_WEEK(startWeek: number) {
    this.startWeek = startWeek;
  }

  @Mutation
  SET_END_WEEK(endWeek: number) {
    this.endWeek = endWeek;
  }

  @Mutation
  SET_SHIFTS(shifts: Record<string, ShiftInterface[]>) {
    this.shifts = shifts;
  }

  @Mutation
  SET_HOURS(hours: Record<string, HoursInterface>) {
    this.hours = hours;
  }

  @Mutation
  SET_CALENDAR(calendar: Record<string, CalendarItem>) {
    this.calendar = calendar;
  }

  @Mutation
  SET_CALENDAR_STYLE(style: Record<string, StyleInterface>) {
    this.calendarStyleByWeeks = style;
  }

  @Mutation
  TOGGLE_MODAL(payload: { type: string; bool: boolean }) {
    (this.modalParams[payload.type] as { isShow: boolean }).isShow = payload.bool;
  }

  @Mutation
  SET_EDIT_SHIFT_ID(id: string) {
    this.modalParams.edit.shiftId = id;
  }

  @Mutation
  SET_DELETE_EMPLOYEE_ID(id: string) {
    this.modalParams.deleteWarning.employeeId = id;
  }

  @Mutation
  SET_DELETE_EMPLOYEE_ACTION(handler: string) {
    this.modalParams.deleteWarning.handlers.yes = handler;
  }

  @Mutation
  UPDATE_HOW_MUCH_TIME(value: string) {
    this.modalParams.edit.howMuchTime = value;
  }

  @Mutation
  SET_IS_TIME_INTERVAL(bool: boolean) {
    this.modalParams.delete.model.isTimeInterval.list[0].checked = bool;
  }

  @Action({ rawError: true })
  async initCalendar(employeeId: string) {
    await this.initWeeksState();
    await this.getShifts(employeeId);
    ShopShiftsModule.initStatuses();
  }

  @Action({ rawError: true })
  async initByWeek(employeeId: string) {
    await this.initWeeksState({ start: this.calendarEntity.defaultStartWeek, end: this.calendarEntity.defaultEndWeek });
    await this.getShifts(employeeId);
    ShopShiftsModule.initStatuses();
  }

  @Action({ rawError: true })
  async getShifts(employeeId: string) {
    try {
      const result = await getEmployeesShifts(employeeId, this.startWeek, this.endWeek);
      await this.setShifts(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: (error as CatchFormResponse).response.data.message ?? strings.UNKNOWN_ERROR,
        type: 'fail',
      });
    }
  }

  @Action({ rawError: true })
  async setShifts(shifts: { id: number; end: string; entityType: string; start: string }[]) {
    const shiftsByDays: Record<string, ShiftInterface[]> = (await shiftsEmployeeProcessed(
      shifts,
      this.statuses,
      EmployeeEntityModule.model.timeZone
    )) as Record<string, ShiftInterface[]>;
    await this.SET_SHIFTS({});
    await this.SET_SHIFTS(shiftsByDays);

    await this.calendarEntity.createWeeks(shiftsByDays);
    await this.calendarEntity.createHours();

    this.SET_CALENDAR(this.calendarEntity.weeks);
    this.SET_CALENDAR_STYLE(this.calendarEntity.styleByWeeks);
    this.SET_HOURS(this.calendarEntity.hours);

    return shiftsByDays;
  }

  @Action({ rawError: true })
  async getWeekByDate({ date, employeeId }: { date: Date; employeeId: string; weekRange?: number }) {
    if (isNaN(date?.valueOf())) {
      return;
    }

    const { startWeek, endWeek, isSameWeek } = await this.prepareWeekRange(date);

    if (isSameWeek) {
      return;
    }

    const shifts = await getEmployeesShifts(employeeId, startWeek, endWeek);
    const shiftsByDays = (await shiftsEmployeeProcessed(
      shifts,
      this.statuses,
      EmployeeEntityModule.model.timeZone
    )) as Record<string, unknown>;

    await this.setCalendarRange({ startWeek, endWeek, shiftsByDays });
  }

  @Action({ rawError: true })
  async getPrevWeek(employeeId: string) {
    await this.context.commit('SET_START_WEEK', this.countWeekStart - 1);
    await this.context.commit('SET_END_WEEK', this.countWeekStart);

    const shifts = await getEmployeesShifts(employeeId, this.startWeek, this.endWeek);
    const shiftsByDays = await shiftsEmployeeProcessed(shifts, this.statuses, EmployeeEntityModule.model.timeZone);
    await this.calendarEntity.addWeek(this.startWeek, shiftsByDays);
    await this.calendarEntity.createHours();

    this.context.commit('SET_CALENDAR', this.calendarEntity.weeks);
    this.context.commit('SET_CALENDAR_STYLE', this.calendarEntity.styleByWeeks);
    this.context.commit('SET_HOURS', this.calendarEntity.hours);

    this.context.commit('SET_COUNT_START_WEEK', this.countWeekStart - 1);
  }

  @Action({ rawError: true })
  async getNextWeek(employeeId: string) {
    const nextEndWeek = this.countWeekEnd + 1;
    await this.context.commit('SET_START_WEEK', this.countWeekEnd);
    await this.context.commit('SET_END_WEEK', nextEndWeek);

    const shifts = await getEmployeesShifts(employeeId, this.startWeek, this.endWeek);
    const shiftsByDays = await shiftsEmployeeProcessed(shifts, this.statuses, EmployeeEntityModule.model.timeZone);
    await this.calendarEntity.addWeek(nextEndWeek, shiftsByDays);
    await this.calendarEntity.createHours();

    this.context.commit('SET_CALENDAR', this.calendarEntity.weeks);
    this.context.commit('SET_CALENDAR_STYLE', this.calendarEntity.styleByWeeks);
    this.context.commit('SET_HOURS', this.calendarEntity.hours);

    this.context.commit('SET_COUNT_END_WEEK', this.countWeekEnd + 1);
  }

  @Action({ rawError: true })
  modalInit(params: { type: string; shiftId?: string; start?: string; end?: string; date?: string; howMuchTime?: string }) {
    this.setEditShiftId(params.shiftId);
    this.setShiftDateDefault(params);
  }

  @Action({ rawError: true })
  setEditShiftId(shiftId?: string) {
    this.context.commit('SET_EDIT_SHIFT_ID', shiftId);
  }

  @Action({ rawError: true })
  setEditEntityType(type: string) {
    this.context.commit('SET_EDIT_TYPE', type);
  }

  @Action({ rawError: true })
  setShiftModalTitle(date?: string) {
    const current = date ? moment(date).format('DD.MM.YYYY') : moment().format('DD.MM.YYYY');
    const dayName = date ? moment(date).format('dd') : moment().format('dd');
    this.context.commit('SET_MODAL_SHIFT_TITLE', { type: 'edit', title: `Рабочее время ${dayName} ${current}` });
  }

  @Action({ rawError: true })
  async setShiftDateDefault(params: { type: string; start?: string; end?: string; date?: string; howMuchTime?: string }) {
    if (params.type === 'add') {
      const current = params.date || moment();
      this.updateDates([moment(current).format('YYYY-MM-DD')]);

      return;
    }

    if (params.type === 'delete') {
      const current = moment().add(1, 'days').format('MM/DD/YYYY');

      this.updateDeleteDateStart(moment(current).format('DD.MM.YYYY'));
      this.updateDeleteDateEnd(moment(current).format('DD.MM.YYYY'));
    }

    await this.updateEditIntervalStart({
      type: 'hour',
      value: moment(params.start).format('HH'),
    });
    await this.updateEditIntervalStart({
      type: 'minute',
      value: moment(params.start).format('mm'),
    });
    await this.updateEditIntervalEnd({
      type: 'hour',
      value: moment(params.end).format('HH'),
    });
    await this.updateEditIntervalEnd({
      type: 'minute',
      value: moment(params.end).format('mm'),
    });

    this.setHowMuchTime(params.howMuchTime ? params.howMuchTime : '');
  }

  @Action({ rawError: true })
  setHowMuchTime(howMuchTime: string) {
    this.context.commit('UPDATE_HOW_MUCH_TIME', howMuchTime);
  }

  @Action({ rawError: true })
  updateHowMuchTime() {
    const timeStart =
      parseInt(this.modalParams.edit.model.intervalStart.hour) * 60 +
      parseInt(this.modalParams.edit.model.intervalStart.minute);
    const timeEnd =
      parseInt(this.modalParams.edit.model.intervalEnd.hour) * 60 + parseInt(this.modalParams.edit.model.intervalEnd.minute);

    const howMuchTime = timeEnd >= timeStart ? timeEnd - timeStart : 24 * 60 - timeStart + timeEnd;
    const howMuchTimeHour = howMuchTime >= 60 ? Math.floor(howMuchTime / 60) : 0;
    const howMuchTimeMinute = howMuchTime >= 60 ? Math.ceil(howMuchTime - howMuchTimeHour * 60) : howMuchTime;

    const lunchHourFormat = howMuchTimeHour === 0 ? '0' : howMuchTimeHour.toString();
    let lunchMinuteFormat = howMuchTimeMinute ? howMuchTimeMinute.toString() : '00';

    if (howMuchTime < 10 && howMuchTime !== 0) {
      lunchMinuteFormat = `0${lunchMinuteFormat}`;
    }

    this.context.commit('UPDATE_HOW_MUCH_TIME', `${lunchHourFormat} ч ${lunchMinuteFormat} мин`);
  }

  @Action({ rawError: true })
  showModal(type: string) {
    this.context.commit('TOGGLE_MODAL', { type: type, bool: true });
  }

  @Action({ rawError: true })
  hideModal(type: string) {
    this.context.commit('TOGGLE_MODAL', { type: type, bool: false });
  }

  @Action({ rawError: true })
  async addNewShift(params: { employeeId: string; data: { includeWeekdays: number; includeWeekends: number } }) {
    try {
      const data = await this.prepareDataShift(params);
      const result = await addNewShift(data as Record<string, string>);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Свободное время создано', type: 'ok' });
        this.hideModal('add');
        await this.updateShift(params.employeeId);
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
      SiteModule.setIsBlock(false);
    } catch (error) {
      if (!error.response.data.message) {
        ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
      } else {
        ResponseHandlerModule.showNotify({ message: 'Свободное время не создано', type: 'fail' });
      }
      this.context.commit('site/SET_IS_BLOCK', false, { root: true });
    }
  }

  @Action({ rawError: true })
  prepareDataShift(params: { employeeId: string; data: { includeWeekdays: number; includeWeekends: number } }) {
    try {
      const data: Record<string, string> = {
        employee: params.employeeId,
        intervalStart: `${this.modalParams.add.model.intervalStart.hour}:${this.modalParams.add.model.intervalStart.minute}:00`,
        intervalEnd: `${this.modalParams.add.model.intervalEnd.hour}:${this.modalParams.add.model.intervalEnd.minute}:00`,
      };

      const dateEntries = Array.from(this.modalParams.add.model.dates.entries());
      for (const [index, date] of dateEntries) {
        data[`dates[${index}]`] = date;
      }

      return Object.assign(data, params.data);
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: (error as CatchFormResponse).response.data.message ?? strings.UNKNOWN_ERROR,
        type: 'fail',
      });
    }
  }

  @Action({ rawError: true })
  async updateShift(employeeId: string) {
    try {
      const shifts = await getEmployeesShifts(employeeId, this.countWeekStart, this.countWeekEnd);
      const shiftsByDays: Record<string, ShiftInterface[]> = (await shiftsEmployeeProcessed(
        shifts,
        this.statuses,
        EmployeeEntityModule.model.timeZone
      )) as Record<string, ShiftInterface[]>;
      await this.SET_SHIFTS({});
      await this.SET_SHIFTS(shiftsByDays);

      this.calendarEntity.setStartWeek(this.countWeekStart);
      this.calendarEntity.setEndWeek(this.countWeekEnd);
      this.calendarEntity.createWeeks(shiftsByDays);
      this.calendarEntity.createHours();

      this.context.commit('SET_CALENDAR', this.calendarEntity.weeks);
      this.context.commit('SET_CALENDAR_STYLE', this.calendarEntity.styleByWeeks);
      this.context.commit('SET_HOURS', this.calendarEntity.hours);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  deleteShiftWarning(params: { employeeId: string; handler: string }) {
    this.context.commit('SET_DELETE_EMPLOYEE_ID', params.employeeId);
    this.context.commit('SET_DELETE_EMPLOYEE_ACTION', params.handler);
    ModalsModule.updateParams(this.modalParams.deleteWarning);
    ModalsModule.openModalByType('warning');
  }

  @Action({ rawError: true })
  async deleteShift() {
    try {
      const params: string = await this.prepareDeleteParams();
      const result = await deleteShift(this.modalParams.deleteWarning.employeeId, params);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Заказы удалены', type: 'ok' });
        this.hideModal('delete');
        await this.updateShift(this.modalParams.deleteWarning.employeeId);
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      await this.updateShift(this.modalParams.deleteWarning.employeeId);
      if (!error.response.data.message) {
        for (const field of Object.values(error.response.data.errors.fields)) {
          // @ts-ignore
          ResponseHandlerModule.showNotify({ message: field[0].message, type: 'fail' });
        }
        this.hideModal('delete');
      } else {
        ResponseHandlerModule.showNotify({ message: error.response.data.message, type: 'fail' });
      }
    }
  }

  @Action({ rawError: true })
  async prepareDeleteParams() {
    try {
      let params = '';
      const dateStart = this.modalParams.delete.model.dateStart.value.split('.');
      const dateEnd = this.modalParams.delete.model.dateEnd.value.split('.');
      params += `?dateStart=${dateStart[2]}-${dateStart[1]}-${dateStart[0]}&dateEnd=${dateEnd[2]}-${dateEnd[1]}-${dateEnd[0]}`;

      const deleteWeekends = this.modalParams.delete.model.isExcludeWeekends.list[0].checked ? 1 : 0;
      const deleteWeekdays = this.modalParams.delete.model.isExcludeWeekday.list[0].checked ? 1 : 0;
      params += `&deleteWeekends=${deleteWeekends}`;
      params += `&deleteWeekdays=${deleteWeekdays}`;

      if (this.modalParams.delete.model.isTimeInterval.list[0].checked) {
        params += `&intervalStart=${this.modalParams.delete.model.intervalStart.hour}:${this.modalParams.delete.model.intervalStart.minute}:00&intervalEnd=${this.modalParams.delete.model.intervalEnd.hour}:${this.modalParams.delete.model.intervalEnd.minute}:00`;
      }

      const deleteShifts = this.modalParams.delete.model.isExcludeShifts.list[0].checked ? 1 : 0;
      params += `&deleteShifts=${deleteShifts}`;

      return params;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });

      return '';
    }
  }

  @Action({ rawError: true })
  async updateReadyTimeInfo(employeeId: string) {
    try {
      const data = await this.prepareReadyTimeData();

      const result = await updateReadyTimeInfo(this.modalParams.edit.shiftId, data);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Заказ обновлен', type: 'ok' });
        this.hideModal('edit');
        await this.updateShift(employeeId);
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
      SiteModule.setIsBlock(false);
    } catch (error) {
      if (!error.response.data.message) {
        ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
      } else {
        ResponseHandlerModule.showNotify({ message: error.response.data.message, type: 'fail' });
      }
    }
  }

  @Action({ rawError: true })
  prepareReadyTimeData() {
    try {
      return {
        intervalStart: `${this.modalParams.edit.model.intervalStart.hour}:${this.modalParams.edit.model.intervalStart.minute}:00`,
        intervalEnd: `${this.modalParams.edit.model.intervalEnd.hour}:${this.modalParams.edit.model.intervalEnd.minute}:00`,
      };
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });

      return {};
    }
  }

  @Action({ rawError: true })
  async deleteReadyTimeById(employeeId: string) {
    try {
      const result = await deleteReadyTimeById(this.modalParams.edit.shiftId);
      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Заказ удален', type: 'ok' });
        this.hideModal('edit');
        await this.updateShift(employeeId);
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      if (!error.response.data.message) {
        ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
      } else {
        ResponseHandlerModule.showNotify({ message: error.response.data.message, type: 'fail' });
      }
    }
  }

  @Action({ rawError: true })
  resetCalendarModel() {
    this.context.commit('SET_HOURS', {});
    this.context.commit('SET_CALENDAR', {});
    this.context.commit('SET_SHIFTS', {});
  }

  @Action({ rawError: true })
  updateIsTimeInterval(payload: { bool: boolean }) {
    this.context.commit('SET_IS_TIME_INTERVAL', payload.bool);
  }

  get calendarInOrder() {
    const entries = Object.entries(this.calendar);

    if (!entries.length) {
      return { deviation: 0, arr: [] };
    }

    const sortEntries = entries.sort((a, b) => +a[0] - +b[0]);

    return {
      deviation: sortEntries[0][0],
      arr: sortEntries.map((el) => el[1]),
    };
  }
}

export default getModule(EmployeesCalendarModule);
