import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cp-admin-title-tab__wrapper" }
const _hoisted_2 = {
  ref: "filter",
  class: "cp-admin-actions__wrapper cp-admin-control__wrapper cp-width__max--full justify-content-start cp-margin__b--small"
}
const _hoisted_3 = { class: "cp-flex align-items-center" }
const _hoisted_4 = { class: "fullName" }
const _hoisted_5 = {
  key: 0,
  class: "cp-badge cp-badge_test cp-margin__l--3"
}
const _hoisted_6 = {
  key: 1,
  class: "cp-badge cp-badge_blue cp-badge_mew cp-margin__l--3"
}
const _hoisted_7 = { class: "cp-flex align-items-center" }
const _hoisted_8 = {
  key: 0,
  class: "cp-badge cp-badge_test cp-margin__l--3"
}
const _hoisted_9 = { class: "cp-flex align-items-center" }
const _hoisted_10 = {
  key: 0,
  class: "cp-badge cp-badge_test cp-margin__l--3"
}
const _hoisted_11 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_nav = _resolveComponent("tabs-nav")!
  const _component_title_return = _resolveComponent("title-return")!
  const _component_form_select = _resolveComponent("form-select")!
  const _component_form_simple_switch = _resolveComponent("form-simple-switch")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_icon_font = _resolveComponent("icon-font")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_text_phone = _resolveComponent("text-phone")!
  const _component_text_datetime = _resolveComponent("text-datetime")!
  const _component_app_simple_checkbox = _resolveComponent("app-simple-checkbox")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_shift_modal = _resolveComponent("shift-modal")!
  const _component_app_row = _resolveComponent("app-row")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, { grow: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_tabs_nav, {
              items: _ctx.tabsNav,
              "active-id": _ctx.currentTab
            }, null, 8, ["items", "active-id"]),
            _createVNode(_component_title_return, {
              icon: "task-sheet",
              "icon-size": "19",
              "is-return": false
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Задачи ")
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            (_ctx.fullName)
              ? (_openBlock(), _createBlock(_component_form_select, {
                  key: 0,
                  modelValue: _ctx.fullName.current ? _ctx.fullName.current : _ctx.fullName.list[0],
                  items: _ctx.fullName.list,
                  clear: true,
                  label: _ctx.fullName.title,
                  "is-search-enabled": true,
                  onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSelect($event, { action: _ctx.fullName.action, key: 'partnerUuid' })))
                }, null, 8, ["modelValue", "items", "label"]))
              : _createCommentVNode("", true),
            (_ctx.selectRange)
              ? (_openBlock(), _createBlock(_component_form_select, {
                  key: 1,
                  class: "cp-margin__l--s",
                  modelValue: _ctx.selectRange.current.value ? _ctx.selectRange.current : _ctx.selectRange.list[0],
                  items: _ctx.selectRange.list,
                  onUpdate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSelect($event, { action: _ctx.selectRange.action, key: 'range' })))
                }, null, 8, ["modelValue", "items"]))
              : _createCommentVNode("", true),
            (_ctx.processed && _ctx.processedItem)
              ? (_openBlock(), _createBlock(_component_form_simple_switch, {
                  key: 2,
                  "model-value": _ctx.processedItem.checked,
                  horizontal: true,
                  label: "Просмотренные",
                  class: "cp-margin__b-no cp-margin__l--s cp-desc",
                  onUpdate: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleCheckbox($event, _ctx.processed.key, _ctx.processed.action)))
                }, null, 8, ["model-value"]))
              : _createCommentVNode("", true)
          ], 512)
        ]),
        _: 1
      }),
      (Object.keys(_ctx.settings.table).length === 0)
        ? (_openBlock(), _createBlock(_component_app_col, {
            key: 0,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_gui_loader, { "absolute-center": "" })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (Object.keys(_ctx.settings.table).length > 0)
        ? (_openBlock(), _createBlock(_component_app_table, {
            key: 1,
            class: "cp-margin__t--small grow-1",
            headers: _ctx.settings.table.titles,
            items: _ctx.settings.table.rows,
            page: _ctx.settings.table.pagination.currentPage,
            "total-pages": _ctx.settings.table.pagination.totalPages,
            "total-items": _ctx.settings.table.totalItems,
            "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
            onChangePage: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination, $event))),
            onSelectAmount: _ctx.selectAmount
          }, {
            "item-fullName": _withCtx(({ value, item }) => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, [
                  _withDirectives(_createVNode(_component_icon_font, {
                    class: "cp-icon-primary__wrapper cp-icon-color-primary",
                    icon: _ctx.iconWhoseType(item.whoseType).icon,
                    size: "15"
                  }, null, 8, ["icon"]), [
                    [_directive_tooltip, _ctx.iconWhoseType(item.whoseType).tooltip]
                  ]),
                  _createVNode(_component_router_link, {
                    class: "app-table-link",
                    to: { name: 'employees_schedule', params: { employeeId: item.employeeId } },
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(value), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]),
                (item.isTest)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Test"))
                  : _createCommentVNode("", true),
                (item.sleptDays >= 90)
                  ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_6, [
                      _createTextVNode(" После перерыва ")
                    ])), [
                      [_directive_tooltip, 'Предыдущий заказ >3 мес. назад']
                    ])
                  : _createCommentVNode("", true)
              ])
            ]),
            "item-market": _withCtx(({ value, item }) => [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_router_link, {
                  class: "app-table-link",
                  to: { name: 'shop_shift', params: { shopId: item.marketId } },
                  target: "_blank"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(value), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]),
                (item.isTestMarket)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, "Test"))
                  : _createCommentVNode("", true)
              ])
            ]),
            "item-client": _withCtx(({ value, item }) => [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_router_link, {
                  class: "app-table-link",
                  to: { name: 'customer_edit', params: { customerId: item.clientId } },
                  target: "_blank"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(value), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]),
                (item.isTestClient)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, "Test"))
                  : _createCommentVNode("", true)
              ])
            ]),
            "item-vacancy": _withCtx(({ value, item }) => [
              _createVNode(_component_router_link, {
                class: "app-table-link",
                to: {
            name: 'template_edit',
            params: { customerId: item.clientId, vacancyId: item.vacancyId },
          },
                target: "_blank"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(value), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            "item-phone": _withCtx(({ value, item }) => [
              value
                ? _withDirectives((_openBlock(), _createBlock(_component_text_phone, {
                    key: 0,
                    value: value,
                    onPhoneClick: ($event: any) => (_ctx.makeCall(item.employeeId))
                  }, null, 8, ["value", "onPhoneClick"])), [
                    [_directive_tooltip, 'Телефон исполнителя']
                  ])
                : _createCommentVNode("", true)
            ]),
            "item-time": _withCtx(({ item }) => [
              _withDirectives((_openBlock(), _createElementBlock("div", {
                class: "app-table-link",
                onClick: ($event: any) => (_ctx.openShift(item))
              }, [
                (item.start)
                  ? (_openBlock(), _createBlock(_component_text_datetime, {
                      key: 0,
                      value: item.start,
                      "format-to": "H:mm"
                    }, null, 8, ["value"]))
                  : _createCommentVNode("", true),
                _createTextVNode(" - "),
                (item.end)
                  ? (_openBlock(), _createBlock(_component_text_datetime, {
                      key: 1,
                      value: item.end,
                      "format-to": "H:mm"
                    }, null, 8, ["value"]))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_11)), [
                [_directive_tooltip, 'Время заказа']
              ])
            ]),
            "item-processed": _withCtx(({ value, item }) => [
              _createVNode(_component_app_simple_checkbox, {
                "is-fill": true,
                "model-value": value,
                "true-value": true,
                "false-value": false,
                onUpdate: ($event: any) => (_ctx.updateProcessed(item.clientMarketShiftEmployeeId, $event))
              }, null, 8, ["model-value", "onUpdate"])
            ]),
            _: 1
          }, 8, ["headers", "items", "page", "total-pages", "total-items", "page-items", "onSelectAmount"]))
        : _createCommentVNode("", true),
      (_ctx.isShowShiftModal)
        ? (_openBlock(), _createBlock(_component_shift_modal, {
            key: 2,
            "shop-id": _ctx.shopId,
            "service-type-id": _ctx.serviceTypeId,
            "shift-id": _ctx.shiftIdForModal,
            onCloseModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isShowShiftModal = false))
          }, null, 8, ["shop-id", "service-type-id", "shift-id"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}