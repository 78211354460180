/**
 * Global API for first shift.
 *
 */

import API from '@/config/api-routers';
import { makeFormRequest, makeGetRequest } from './requests';
import { AssemblerUrl } from '@/lib/UtilsApi';
import { FormResponse } from '@/interfaces/shared';

/**
 * Get first shift list
 *
 * @param page
 * @param filter
 * @param pageAmountItems
 *
 * @access CAN_SET_FIRST_EMPLOYEE_SHIFT_PROCESSED
 */
export const getFirstShiftList = async (page: number, filter: string, pageAmountItems: string) => {
  const assemblerUrl = new AssemblerUrl(API.firstShift.getList);
  const url = assemblerUrl.addPage(page).addAmount(pageAmountItems).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Update IsProcessed
 *
 * @param data
 *
 * @access CAN_SET_FIRST_EMPLOYEE_SHIFT_PROCESSED
 */
export const updateIsProcessed = async (data: {}): Promise<FormResponse> => {
  const url = API.firstShift.edit;
  const result = await makeFormRequest(url, data);

  return result.data;
};
