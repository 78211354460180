<template>
  <div class="mr-filter__wrapper">
    <div class="mr-filter-title" @click="openModal">
      <font-awesome-icon class="cp-btn-add" size="lg" :icon="['fas', 'filter']" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

library.add(faFilter);
export default {
  props: ['action'],
  methods: {
    ...mapActions(['filter/openModal']),
    openModal() {
      this['filter/openModal']();
    },
    clearFilter() {
      this.$store.dispatch(this.action);
    },
  },
};
</script>
